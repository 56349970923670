import React from 'react';
import './App.css';
import Home from './pages/Home';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <div id="container">
        <Home />
      </div>
      <Footer />
    </>
  );
}

export default App;
